import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatInputDate } from 'helpers/utils'
import { SpinnerCircular } from 'spinners-react'
import { loadingStatuses } from '../constants'
import cx from 'classnames'

const Card = styled('div')`
  padding: 2px;
  border: none;

  .card-top {
    overflow: hidden;

    .spinner {
      height: auto;
      padding: calc(75% - 25px) 0;
    }
  }

  .person {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    height: 100%;
  }

  a {
    height: 100%;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      background-color: #eee;
    }
  }

  .new-person {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ccc;
      &:hover {
        color: #aaa;
      }
    }
  }

  .hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: pointer;

    img {
      display: block;
      position: relative;
      -webkit-transition: all .4s linear;
      transition: all .4s linear;

      &.pending {
        display: none;
      }


    }

    &:hover img {
      -ms-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
`

const PersonCard = (props) => {
  const { person, path, onOpenModal } = props
  const [loadingStatus, setLoaded] = useState(loadingStatuses.PENDING)
  const imageRef = useRef(null)

  useEffect(() => {
    const image = imageRef.current

    if (image && image.complete) {
      handleImageLoaded();
    } else {
      setLoaded(loadingStatuses.PENDING)
    }
  }, [person])

  const handleImageLoaded = () => {
    if (loadingStatus != loadingStatuses.LOADED) {
      setLoaded(true);
    }
  }

  return (
    <Card className="card col">
      {
        person ? (
          <div className="person">
            <Link className="hovereffect" to={path || `/home/${person?.id}`}>
              <div className="card-top">
                {
                  person?.photo_url && (
                    <>
                      <div>
                        {
                          loadingStatus == loadingStatuses.PENDING && (
                            <div className='spinner'>
                              <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
                            </div>
                          )
                        }
                      </div>
                      <img className={cx("card-img-top", { pending: loadingStatus == loadingStatuses.PENDING })} src={person?.photo_url} alt={person?.first_name} ref={imageRef} onLoad={handleImageLoaded}/>
                    </>
                  )
                }
              </div>
              <div className="card-body text-center text-dark">
                <div className="card-title font-weight-bold">
                  {person?.full_name}
                </div>{formatInputDate(person?.born_date)}
              </div>
            </Link>
          </div>
          ) : (
            <>
              <div className="person new-person">
                <a data-toggle="modal" href="#modal-child" onClick={onOpenModal}><i className="fa fa-plus fa-4x"></i></a>
              </div>
            </>
          )
      }
    </Card>
  )
}

export default PersonCard
