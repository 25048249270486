import { useEffect, useState, useContext } from 'react'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { MessageContext } from 'App'
import { getPermission, updatePermission } from 'api'
import { PermissionForm } from 'components'

const Container = styled.div`
`

const PermissionsEditPage = () => {
  const { showSuccess, showError } = useContext(MessageContext)
  const navigate = useNavigate()
  const { id } = useParams()
  const [permission, setPermission] = useState(null)
  const [searchParams, _] = useSearchParams()

  useEffect(() => { getPermission(id).then(setPermission) }, [id])

  const onUpdate = () => {
    const params = {
      permission: {
        recipient_id: permission.recipient_id,
        show_attributes: {
          person_ids: permission.show.map(({ id }) => id)
        },
        edit_attributes: {
          person_ids: permission.edit.map(({ id }) => id)
        }
      }
    }

    updatePermission(permission.id, params).then((permission) => {
      showSuccess('Правы доступа пасьпяхова абноўлены')

      setPermission(permission)

      navigate(searchParams.get('redirect_path') || '/settings/permissions')
    }).catch((respose) => {
      showError()
    })
  }

  return (
    <Container>
      <br/>
      <Link className='text-dark' onClick={() => window.history.back()}>Назад</Link>
      <br/>
      <br/>
      <h2>Рэдагаваньне правоў доступа</h2>
      <br/>
      <PermissionForm permission={permission} setPermission={setPermission} onSubmit={onUpdate}/>
    </Container>
  )
}

export default PermissionsEditPage
