import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { getPeople } from 'api'
import { PersonCard } from 'components'
import { loadingStatuses } from '../constants';
import { WithLoader } from 'components'

const Container = styled('div')`
  .card-header {
    cursor: pointer;
  }
`

const PeoplePage = ({ setLoading }) => {
  const [people, setPeople] = useState([])

  useEffect(() => {
    getPeople().then((people) => {
      setPeople(people)

      setLoading(loadingStatuses.LOADED)
    })
  }, [setLoading])

  let chunkSize = 6
  let peopleChunks = []

  for (let i = 0; i < people.length; i += chunkSize) {
    peopleChunks.push(people.slice(i, i + chunkSize));
  }

  return (
    <Container>
      {
        peopleChunks.map((peopleChunk, indexChunk) => {
          return (
            <div key={indexChunk} className='row'>
              {
                peopleChunk.map((person, index) => {
                  return (
                    <PersonCard key={index} person={person.attributes} path={`/people/${person?.id}`}/>
                  )
                })
              }
            </div>
          )
        })
      }
    </Container>
  )
}

export default WithLoader(PeoplePage)


// .row
//   - @people.each do |person|
//     .card.col-md-2
//       .person
//         = link_to person, class: 'hovereffect' do
//           .card-top
//             - if person.photo.present?
//               = image_tag person.photo.thumb('400x600#').url, class: 'card-img-top'
//           .card-body.text-center.text-dark
//             .card-title.font-weight-bold = person.full_name
//             = person.born_date
//   - if can? :create, Person
//     .card.col-md-2
//       .person.new-person
//         = link_to new_person_path do
//           = fa_icon 'plus', class: 'fa-4x'
