import { memo, useEffect, useState, useRef, useCallback, useContext } from 'react'
import styled from 'styled-components'
import 'helpers/plugins/imgAreaSelect/imgAreaSelect.css'
import cx from 'classnames'

import { MessageContext } from 'App'
import { DocumentExplorer, FileUploader } from 'components'
import { getDocuments, createDocument } from 'api'
import { loadingStatuses } from '../constants';
import { WithLoader } from 'components'

const Container = styled.div`
`

const DocumentsPage = ({ setLoading }) => {
  const { showSuccess } = useContext(MessageContext)
  const [documents, setDocuments] = useState([])

  const [files, setFiles] = useState([])
  const filesRef = useRef(null)

  useEffect(() => { getDocuments().then((documents) => {
    setDocuments(documents)

    setLoading(loadingStatuses.LOADED)
  }) }, [])

  const onFileUpload = useCallback((e) => {
    const newFiles = e.target.files

    setFiles((files) => [...files, ...newFiles])
  }, [])

  const onUploaded = useCallback((document) => {
    showSuccess('Дакумент пасьпяхова дабаўлены')
    setDocuments((documents) => [document, ...documents])
  }, [showSuccess])

  const FileUploaders = useCallback(() => {
    return (
      <div className='Files' ref={filesRef}>
        {
          files.map((file, index) => {
            return <FileUploader key={index} type='document' file={file} createFile={createDocument} onUploaded={onUploaded}/>
          })
        }
      </div>
    )
  }, [files, onUploaded])

  return (
    <Container>
      <br />
      <div className="Menu">
        <div className='Files-Uploader'>
          <input type='file' accept='*' multiple onChange={onFileUpload}/>
          <FileUploaders/>
        </div>
      </div>
      <br/>

      <DocumentExplorer documents={documents} setDocuments={setDocuments}/>
    </Container>
  )
}

export default memo(WithLoader(DocumentsPage))
