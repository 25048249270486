import client from './client';
import Cookies from 'js-cookie';
import { API_URL, TOKEN } from 'constants'

export function getPermission(id) {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/settings/permissions/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getPermissions() {
  return client.get(`${API_URL}/api/v1/settings/permissions.json`, { withCredentials: true })
    .then(response => response.data.data)
}

export function createPermission(params) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/settings/permissions.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function updatePermission(id, params) {
  const token = Cookies.get(TOKEN)

  return client.put(`${API_URL}/api/v1/settings/permissions/${id}.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function deletePermission(id) {
  const token = Cookies.get(TOKEN)

  console.log('id', id)

  return client.delete(`${API_URL}/api/v1/settings/permissions/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}
