import { useEffect, useState, useMemo, useRef, useCallback, useContext } from 'react'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import 'helpers/plugins/imgAreaSelect/imgAreaSelect.css'
import cx from 'classnames'

import { MessageContext } from 'App'
import { Select, TextField, PhotoExplorer, FileUploader } from 'components'
import { getPeopleList, createPhoto } from 'api'
import { peopleToSelectOptions } from 'helpers/utils'

const Container = styled.div`
  .Menu {
    display: flex;
    flex-direction: column;

    >div {
      margin: 0;
    }
  }

  .Filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Files-Uploader {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .Files {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

const PhotosPage = () => {
  const { showSuccess } = useContext(MessageContext)
  const [photos, setPhotos] = useState([])
  const [filter, setFilter] = useState({})
  const [initializedFilter, setInitializedFilter] = useState(false)
  const [search, setSearch] = useState(null)
  const [selectedPeople, setSelectedPeople] = useState([])
  const [peopleList, setPeopleList] = useState([])
  const [status, setStatus] = useState(null)
  const statusOptions = [
    { label: '', value: null },
    { label: 'Неапрацаваныя', value: 'draft' },
    { label: 'Адкладзеныя', value: 'pending' },
    { label: 'Апрацаваныя', value: 'completed' },
  ]
  const peopleOptions = useMemo(() => peopleList?.map(peopleToSelectOptions), [peopleList])
  const orderOptions = [
    { label: 'Дата запампоўкі (ад новых)', value: 'created_at desc' },
    { label: 'Дата запампоўкі (ад старых)', value: 'created_at asc' },
    { label: 'Дата фотаздымка (ад новых)', value: 'date desc' },
    { label: 'Дата фотаздымка (ад старых)', value: 'date asc' }
  ]
  const [order, setOrder] = useState(orderOptions[0])
  const [files, setFiles] = useState([])
  const filesRef = useRef(null)

  const toogleFilter = (name) => setFilter({ ...filter, [name]: !filter[name] })

  useEffect(() => {
    getPeopleList().then(setPeopleList)
  }, [])

  useEffect(() => {
    if (peopleOptions.length) {
      const queryParams = new URLSearchParams(window.location.search);

      const todayValue = queryParams.get('today')
      const withTagsValue = queryParams.get('withTags')
      const withoutTagsValue = queryParams.get('withoutTags')
      const withDateValue = queryParams.get('withDate')
      const withoutDateValue = queryParams.get('withoutDate')

      const initialFilter = {
        ...(todayValue ? { today: todayValue === 'true' } : {}),
        ...(withTagsValue ? { withTags: withTagsValue === 'true' } : {}),
        ...(withoutTagsValue ? { withoutTags: withoutTagsValue === 'true' } : {}),
        ...(withDateValue ? { withDate: withDateValue === 'true' } : {}),
        ...(withoutDateValue ? { withoutDate: withoutDateValue === 'true' } : {}),
      };

      setFilter(initialFilter);

      const initialOrderValue = queryParams.get('order')

      if (initialOrderValue) {
        setOrder(orderOptions.find(({ value }) => value == initialOrderValue))
      }

      const initialSearchValue = queryParams.get('search')

      if (initialSearchValue) {
        setSearch(initialSearchValue)
      }

      const initialSelectedPeople = queryParams.getAll('selectedPeople').map((id) => peopleOptions.find(({ value }) => value == id))

      setSelectedPeople(initialSelectedPeople)

      const initialStatusValue = queryParams.get('status')

      if (initialStatusValue) {
        setStatus(statusOptions.find(({ value }) => value === initialStatusValue))
      }
    }
  }, [peopleOptions])

  useEffect(() => {
    if (peopleOptions.length) {
      const queryParams = new URLSearchParams({
        ...filter,
        ...(order ? { order: order.value } : {}),
        ...(search ? { search } : {}),
        ...(status ? { status: status.value } : {}),
      })

      selectedPeople.forEach(({ value }) => {
        queryParams.append("selectedPeople", value);
      })

      window.history.pushState(null, null, `?${queryParams.toString()}`);
    }
  }, [peopleOptions, filter, order, search, selectedPeople, status])

  useEffect(() => {
    if (peopleOptions.length) {
      setInitializedFilter(true)
    }
  }, [peopleOptions])

  const onChangePeopleSelected = (options, _) => {
    setSelectedPeople(options)
  }

  const onChangeStatus = (option) => {
    setStatus(option.value ? option : null)
  }

  const selectedPersonIds = useMemo(() => {
    return selectedPeople.map(({ value }) => value) || []
  }, [selectedPeople])

  const onFileUpload = (e) => {
    const newFiles = e.target.files

    setFiles([...files, ...newFiles])
  }

  const onUploaded = useCallback((photo) => {
    showSuccess('Фотаздымак пасьпяхова дабаўлены')
    setPhotos((photos) => [photo, ...photos])
  }, [showSuccess])

  return (
    <Container>
      <br />
      <div className="Menu">
        <div className="spinner justify-content-center" style={{display: 'none'}}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <div className='Filters'>
          <div>
            <span className={cx('btn btn-light', { active: filter['today'] })} onClick={() => toogleFilter('today')}>
              Сёньняшнія
            </span>
            <span className={cx('btn btn-light', { active: filter['withTags'] })} onClick={() => toogleFilter('withTags')}>
              З тэгамі
            </span>
            <span className={cx('btn btn-light', { active: filter['withoutTags'] })} onClick={() => toogleFilter('withoutTags')}>
              Без тэгаў
            </span>
            <span className={cx('btn btn-light', { active: filter['withDate'] })} onClick={() => toogleFilter('withDate')}>
              З датай
            </span>
            <span className={cx('btn btn-light', { active: filter['withoutDate'] })} onClick={() => toogleFilter('withoutDate')}>
              Без даты
            </span>
          </div>
          <TextField value={search || ''} onChange={(e) => setSearch(e.target.value)}/>
          <Select value={order || ''} onChange={setOrder} options={orderOptions}/>
          <ReactSelect
            isMulti
            placeholder="Людзі"
            value={selectedPeople}
            onChange={onChangePeopleSelected}
            options={peopleOptions}
            className="basic-multi-select"
            classNamePrefix="select"
          />
          <ReactSelect
            placeholder="Статус"
            value={status}
            onChange={onChangeStatus}
            options={statusOptions}
            classNamePrefix="select"
          />
        </div>
        <div className='Files-Uploader'>
          <input type='file' accept='image/*' multiple onChange={onFileUpload}/>
          <div className='Files' ref={filesRef}>
            {
              [...files].map((file, index) => {
                return <FileUploader key={index} type='photo' file={file} createFile={createPhoto} onUploaded={onUploaded}/>
              })
            }
          </div>
        </div>
      </div>
      <hr/>

      {
        initializedFilter && (
          <PhotoExplorer photos={photos} setPhotos={setPhotos} search={search} filter={filter} order={order?.value} status={status?.value} selectedPersonIds={selectedPersonIds} peopleOptions={peopleOptions}/>
        )
      }
    </Container>
  )
}

export default PhotosPage
