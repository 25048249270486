import { useState, useEffect, useCallback, useRef, Fragment, useContext } from 'react'
import styled from 'styled-components'
import PhotoLoader from './PhotoLoader'
import { getPhotos, deletePhoto } from 'api'
import { PHOTOS_LIMIT as limit } from 'constants'
import { MessageContext } from 'App'
import { PhotoExplorerModal } from './PhotoExplorerModal'

import 'lightgallery/scss/lightgallery.scss'
import 'lightgallery/scss/lg-thumbnail.scss'
import 'lightgallery/scss/lg-fullscreen.scss'
import 'lightgallery/scss/lg-transitions.scss'

const Container = styled('div')`
  position: relative;

  .Photos {
    display: flex;
    flex-wrap: wrap;
  }

  .Photo {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:hover {
      opacity: 0.9;

      .Photo-Action {
        opacity: 1;
      }
    }
  }

  .Photo-Action {
    opacity: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: opacity 0.2s;
  }

  .Image {
    width: 180px;
    height: 180px;
  }

  .Observer {
    // border: 1px solid #000;
    position: absolute;
    bottom: 400px;
  }
`

const PhotoExplorer = ({ photos, setPhotos, search, filter, order, selectedPersonIds, status, peopleOptions }) => {
  const { showSuccess, showError } = useContext(MessageContext)
  const [total, setTotal] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const observerTarget = useRef(null)
  const [selectedPhotoId, setSelectedPhotoId] = useState(null)

  useEffect(() => {
    if (isIntersecting) {
      if (!hasMore || isLoading) return

      setIsLoading(true)

      getPhotos(limit, page, search, filter, status, order, selectedPersonIds).then((photos) => {
        if (photos.data.length === 0) setHasMore(false)
        setPhotos((prevPhotos) => [...prevPhotos, ...photos.data])
        setTotal(photos.total)
        setPage((prevPage) => prevPage + 1)
        setIsLoading(false)
      })
    }
  }, [isIntersecting])

  useEffect(() => {
    const observer = new IntersectionObserver(([target]) => setIsIntersecting(target.isIntersecting), { threshold: 0.1 })

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    // return () => {
    //   if (observerTarget.current) {
    //     observer.unobserve(observerTarget.current)
    //   }
    // }
  }, [])

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)
      await getPhotos(limit, 0, search, filter, status, order, selectedPersonIds).then((photos) => {
        setPhotos(photos.data)
        setTotal(photos.total)
      })
      setPage(1)
      setHasMore(true)
      setIsLoading(false)
    }

    getData()
  }, [search, filter, order, selectedPersonIds, setPhotos, status])

  const onPhotoDelete = (event, id) => {
    event.stopPropagation()
    event.preventDefault()

    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць фотаздымак?`)) {
      deletePhoto(id).then((response) => {
        showSuccess('Фотаздымак пасьпяхова выдалены')

        setPhotos([
          ...photos.filter(({ id }) => id != response.id)
        ])
      }).catch((respose) => {
        showError()
      })
    }
  }

  console.log('selected', selectedPhotoId)

  const onPhotoSelect = (id) => {
    setSelectedPhotoId(id)
  }

  return (
    <Container>
      <div>
        <span>Усяго: {total}</span>
      </div>
      <div className='Photos'>
        {
          photos.map(({ attributes: { id, file: { url, thumb_url } }}, index) => {
            return (
              <div className='Photo' key={`${index}-${id}`} onClick={() => onPhotoSelect(id)}>
                {/* <span className="Photo-Action" onClick={(e) => {onPhotoDelete(e, id) }}>
                  <i className="fa fa-times"></i>
                </span> */}
                <img className="Image img-responsive" id={id} src={thumb_url} alt={id}/>
              </div>
            )
          })
        }
      </div>
      {isLoading && <PhotoLoader/>}
      <div className='Observer' ref={observerTarget}></div>
      {
        selectedPhotoId && <PhotoExplorerModal id={selectedPhotoId} setId={setSelectedPhotoId} peopleOptions={peopleOptions} setPhotos={setPhotos}></PhotoExplorerModal>
      }
    </Container>
  )
}

export default PhotoExplorer
