import { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import 'helpers/plugins/imgAreaSelect/imgAreaSelect.css'

import { getPermission } from 'api'

const Container = styled.div`
`

const PermissionsShowPage = () => {
  const { id } = useParams()
  const [permission, setPermission] = useState({})

  useEffect(() => { getPermission(id).then(setPermission) }, [id])

  return (
    <Container>
      <br/>
      <Link className='text-dark' onClick={() => window.history.back()}>Назад</Link>
      <br/>
      <br/>
      <h2>
        Правы доступа для <Link to={`/people/${permission?.recipient?.id}`}>{permission?.recipient?.full_name}</Link>
        <Link className="btn text-dark" to={`/settings/permissions/${id}/edit`}>
          <i className="fa fa-pencil"></i>
        </Link>
      </h2>
      <br/>
      {
        permission?.show?.lenght && (
          <div>
            <h5>Правы на прагляд</h5>
            <ul>
              {
                permission?.show?.map(({ id, full_name }) => {
                  return (
                    <li>
                      <Link key={id} to={`/people/${id}`}>{full_name}</Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        )
      }
      {
        permission?.edit?.lenght && (
          <div>
            <h5>Правы на рэдагаваньне</h5>
            <ul>
              {
                permission?.edit?.map(({ id, full_name }) => {
                  return (
                    <li>
                      <Link key={id} to={`/people/${id}`}>{full_name}</Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        )
      }
    </Container>
  )
}

export default PermissionsShowPage
