import { useState, useEffect } from 'react'
import { SpinnerCircular } from 'spinners-react'
import { loadingStatuses } from '../constants';

export const WithLoader = (WrappedComponent) => {
  function HOC(props) {
    const [loadingStatus, setLoading] = useState(loadingStatuses.PENDING);

    return <>
      {
        loadingStatus == loadingStatuses.PENDING && (
          <div className='spinner'>
            <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
          </div>
        )
      }
      <WrappedComponent {...props} setLoading={setLoading} />
    </>
  }

  return HOC
}

export default WithLoader

