import { useEffect, useState, useRef, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link, useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { setPreviousPath } from 'store/actions';
import styled from 'styled-components'
import { MessageContext } from 'App'
import { getPerson, getPersonPhotos, deletePerson, createNote, createRequestPermission } from 'api'
import { TreePanel, Photos } from 'components'
import { formatInputDate } from 'helpers/utils'
import { loadingStatuses } from '../constants'
import { WithLoader } from 'components'

const Container = styled('div')`
  .card-header {
    cursor: pointer;
  }

  .Photos {
    .card-body {
      padding: 0;
    }
  }
`

const PersonLink = ({ name, id }) => {
  return (
    <Link to={`/people/${id}`}>{name}</Link>
  )
}

const PeopleShowPage = ({ setLoading, previousPath, setPreviousPath }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { showSuccess, showError } = useContext(MessageContext)
  const [searchParams, _] = useSearchParams()
  const { id } = useParams()
  const [person, setPerson] = useState(null)
  const [message, setMessage] = useState('')
  const closeModalButtonRef = useRef(null)
  const [photos, setPhotos] = useState([])

  const sendRequest = useCallback((personId) => {
    const params = { person_id: personId, action: 'show' }

    createRequestPermission({ request_permission: params }).then((requestPermission) => {
      showSuccess('Запыт пасьпяхова адпраўлены')
    }).catch(() => {
      showError()
    })
  }, [])

  useEffect(() => {
    getPerson(id).then((person) => {
      setPerson(person)
      setPreviousPath(location.pathname)
    }).catch((response) => {
      const data = response.response.data.data

      const subject = <Link to={`/people/${data.subject.id}`}>{data.subject.full_name}</Link>
      const author = <Link to={`/people/${data.subject.created_by_id}`}>аўтара</Link>
      const request = <button onClick={() => sendRequest(data.subject.id)}>сюды</button>
      const message = <>Вы ня маеце правоў на прагляд старонкі {subject}. Каб запытаць дазвол ў {author} націсьніце {request}</>

      showError(message)
      navigate(previousPath || '/')
    }).finally(() => {
      setLoading(loadingStatuses.LOADED)
    })
    getPersonPhotos(id).then(setPhotos)
  }, [id, showError, sendRequest])

  const closeModal = () => {
    closeModalButtonRef.current.click()
  }

  const onDelete = () => {
    if (window.confirm(`Вы упэўнены, што хочаце выдаліць: ${person.full_name}?`)) {
      deletePerson(person.id).then(() => {
        navigate(searchParams.get('redirect_path') || '/people')
      })
    }
  }

  const onCreateNote = () => {
    const params = {
      person_id: id,
      message
    }

    createNote(params).then((note) => {
      showSuccess('Заўвага пасьпяхова адпраўлена')
      closeModal()
      setMessage('')
    }).catch((respose) => {
      showError()
    })
  }

  return (
    <Container className="container">
      <div className="person" data-person-id="1">
        <div className="row">
            <div className="col-sm-11 col-md-2">
              <img className="img-fluid" src={person?.photo_url} alt={person?.first_name}/>
            </div>
            <div className="col-sm-1 col-md-10 text-center">
              <h2>{person?.full_name}</h2>
              {
                person?.born_date && <p><span>Дата нараджэньня:</span> <span>{formatInputDate(person?.born_date)}</span></p>
              }
              {
                person?.died_date && <p><span>Дата сьмерці:</span> <span>{formatInputDate(person?.died_date)}</span></p>
              }
              <p><span>Мясціны жыцьця:</span> <span>{person?.location}</span></p>
              <Link className="btn" to={`/home/${person?.id}`}><i className="fa fa-user"></i></Link>
              <a className="btn" data-toggle="modal" href="#tree-modal"><i className="fa fa-sitemap"></i></a>
              <Link className="btn" to={`/people/${person?.id}/edit`}><i className="fa fa-pencil"></i></Link>
              {/* <button usePrompt="Вы упэўнены?" className="btn" to={`/people/${person?.id}`}><i className="fa fa-user-times"></i></Link> */}
              <button className="btn" onClick={onDelete}><i className="fa fa-user-times"></i></button>
            </div>
        </div>
        <div id="accordion">
          {
            person?.comment && (
              <div className="card">
                <div aria-controls="collapseComment" aria-expanded="true" className="card-header" data-target="#collapseComment" data-toggle="collapse" id="headingComment">
                  <h4>Біяграфія</h4>
                </div>
                <div aria-labelledby="headingComment" className="collapse" id="collapseComment">
                  <div className="card-body">
                    {person?.comment.split("\n").map((line, index) => (<p key={index}>{line}</p>))}
                  </div>
                </div>
              </div>
            )
          }
          {
            (photos?.length > 0) && (
              <div className="card Photos">
                <div aria-controls="collapsePhoto" aria-expanded="true" className="card-header" data-target="#collapsePhoto" data-toggle="collapse" id="headingPhoto">
                  <h4>Фотаздымкі</h4>
                </div>
                <div aria-labelledby="headingPhoto" className="collapse" id="collapsePhoto">
                  <div className='card-body'>
                    <Photos id={id} photos={photos}/>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <br/>
        {
          person?.created_by && (
            <>
              <div className="text-center">
                <p>
                  <b>Аўтар: </b>
                  <i>{person?.created_by?.full_name}</i>
                  <a className="btn" data-toggle="modal" href="#add-note"><i className="fa fa-envelope"></i></a>
                </p>
              </div>
            </>
          )
        }
        <TreePanel person={person}/>
        <div aria-hidden="true" aria-labelledby="myModalLabel" className="modal fade" id="add-note" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header d-block">
                <button ref={closeModalButtonRef} aria-label="Close" className="close float-right" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button><button aria-label="Close" className="close float-left" data-dismiss="modal" type="button"><span aria-hidden="true">&nbsp;</span></button>
                <h5 className="modal-title text-center">Заўвага</h5>
              </div>
              <div className="modal-body text-center">
                <div className="actions">
                  <div>
                    <div className="form-group text required note_message">
                      <textarea className="form-control text required" value={message} onChange={(e) => setMessage(e.target.value)}>
                      </textarea>
                    </div>
                    <button type="submit" className="btn btn-default" data-disable-with="Адправіць" onClick={onCreateNote}>Адправіць</button>
                  </div>
                </div>
                <div className="spinner justify-content-center" style={{display: 'none'}}>
                  <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  (state) => ({
    previousPath: state.main.previousPath
  }),
  (dispatch) => ({
    setPreviousPath: (path) => dispatch(setPreviousPath(path)),
  })
)(WithLoader(PeopleShowPage));
