import { useState, useContext } from 'react'
import { connect } from 'react-redux'
import { NavLink as Link, useNavigate } from 'react-router-dom'

import { login } from 'api'
import { setCurrentUser, setPreviousPath, setUnreadEventsCount } from 'store/actions'
import { MessageContext } from 'App'

import styled from 'styled-components'

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 80vh;
  background-image: url(background.jpg);
  background-size: cover;
  background-position: bottom;

  .Wrapper {
    width: 320px;
  }

  .Logo {
    text-align: center;
    font-size: 64px;
    font-family: sans-serif;
    margin-bottom: 32px;
  }

  .Form {
    flex-direction: column;
    gap: 16px;
    display: flex;
    text-align: center;

    input {
      font-size: 18px;
      padding: 8px;
      text-align: center;
    }

    button {
      font-size: 18px;
      padding: 8px;
      cursor: pointer;
      background-color: #6f6;

      &:hover {
        background-color: #2e2;
      }
    }
  }
`

const LoginPage = ({ setCurrentUser, setUnreadEventsCount, previousPath, setPreviousPath }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const { showSuccess, showError } = useContext(MessageContext)

  const onSubmit = () => {
    login({
      user: {
        email, password
      }
    }).then(({ data, status, errors }) => {
      if (errors) {
        errors.forEach(({ text }) => {
          showError(text)
        })
      } else {
        setCurrentUser(data.user)
        setUnreadEventsCount(data.unread_events_count)
        navigate(previousPath || '/')
        setPreviousPath(null)
        data.messages.forEach(({ text }) => showSuccess(text))
      }
    })
  }

  return (
    <Container>
      <div className='Wrapper'>
        <div className='Logo'>
          DREVA
        </div>
        <div className='Form'>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='email'
            type='email'
          />
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='пароль'
            type='password'
          />
          <button onClick={onSubmit}>Увайсьці</button>
          <div>або</div>
          <Link to='/signup'>Зарэгістравацца</Link>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
    previousPath: state.main.previousPath
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
    setUnreadEventsCount: (count) => dispatch(setUnreadEventsCount(count)),
    setPreviousPath: (path) => dispatch(setPreviousPath(path)),
  })
)(LoginPage)
