import { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { Modal } from 'components/Modal';
import { SpinnerCircular } from 'spinners-react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import ImageMapper from 'react-img-mapper'
import jQuery from 'jquery'
import imgAreaSelect from 'helpers/plugins/imgAreaSelect/imgAreaSelect'
import 'helpers/plugins/imgAreaSelect/imgAreaSelect.css'
import cx from 'classnames'

import { Select, TextField, Textarea } from 'components'
import { MessageContext } from 'App'
import { getPeopleList, getPhoto, updatePhoto, deletePhoto, createTag, updateTag, deleteTag } from 'api'
import { formatInputDate, peopleToSelectOptions } from 'helpers/utils'

window.jQuery = jQuery
imgAreaSelect(jQuery)

const Container = styled.div`
  label {
    margin: 0;
  }

  .Tools {
    display: flex;

    &-Item {
      flex-grow: 1;
    }
  }

  .TagsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    row-gap: 0;
  }

  .Tags {
    display: flex;

    button {
      padding: 0;
      border: none;
      background: none;
      color: #007bff;
    }

    .Actions {
      display: flex;
      gap: 4px;
    }

    .Tag__Selected {
      color: #0a0;
      transform: scale(1.5);
    }
  }

  .image {
    #img-mapper {
      min-width: 100%;
    }
  }

  .img-mapper-img {
    width: 100%;
  }

  .Button {
    border: 1px solid #c0c0c0;
    padding: 12px;
    width: 100%;
    background-color: #007dff;
    color: #fefefe;
  }
`

const MOUSEOVER = new MouseEvent('mouseover', { view: window, bubbles: true, cancelable: true })
const MOUSEOUT = new MouseEvent('mouseout', { view: window, bubbles: true, cancelable: true })


export const PhotoExplorerModal = ({ id, setId, peopleOptions, setPhotos }) => {
  const navigate = useNavigate()
  const { showSuccess, showError } = useContext(MessageContext)
  const [searchParams, _] = useSearchParams()
  // const [peopleList, setPeopleList] = useState([])
  const [photo, setPhoto] = useState(null)
  const [coords, setCoords] = useState(null)
  const [ratio, setRatio] = useState(null)
  const [selectedTagIndex, setSelectedTagIndex] = useState(null)

  // const peopleOptions = useMemo(() => peopleList?.map(peopleToSelectOptions), [peopleList])

  const statusOptions = [
    { label: 'Неапрацаваныя', value: 'draft' },
    { label: 'Адкладзеныя', value: 'pending' },
    { label: 'Апрацаваныя', value: 'completed' },
  ]

  const onChangeByName = (name) => (e) => setPhoto({ ...photo, [name]: e.value ?? e.target.value})

  useEffect(() => { getPhoto(id).then(setPhoto) }, [id])
  // useEffect(() => { getPeopleList().then(setPeopleList) }, [])

  const onRequestClose = () => {
    setId(null)
    setCoords(null)
    setSelectedTagIndex(null)
    jQuery('img.img-mapper-img').data('imgAreaSelect').cancelSelection()
  }

  const onMouseAreaTrigger = (index, event) => () => document.getElementsByTagName('area')[index]?.dispatchEvent(event)

  const onSelectEnd = useCallback((ratio) => (_, selection) => {
    setCoords([selection.x1, selection.y1, selection.x2, selection.y2].map((coord) => coord * (ratio)))
  }, [])

  useEffect(() => {
    if (selectedTagIndex !== null) {
      const image = jQuery('img.img-mapper-img').data('imgAreaSelect')

      image.setOptions({ show: true })
      image.setSelection(...(photo?.tags[selectedTagIndex].coords || []), ratio)
      image.update()
    } else {
      jQuery('img.img-mapper-img').data('imgAreaSelect')?.cancelSelection()
    }
  }, [photo?.tags, selectedTagIndex, ratio])

  useEffect(() => {
    if (coords && coords[0] === coords[2]) {
      setSelectedTagIndex(null)
    }
  }, [coords])

  const onLoadImageMapper = (image, dimensions) => {
    window.image = jQuery('img.img-mapper-img').data('imgAreaSelect')

    const ratio = photo?.file?.metadata?.width / dimensions?.width
    setRatio(ratio || 1)

    jQuery(image).imgAreaSelect({ onSelectEnd: onSelectEnd(ratio) })

    const areas = document.getElementsByTagName('area')
    photo?.tags.forEach(({ title }, index) => {
      areas[index].setAttribute('title', title)
    })
  }

  const onClickImageMapper = ({ href }, index, event) => {
    navigate(href)
  }

  const areas = useMemo(() => photo?.tags?.map(({ id, coords, person_id }) => ({
    "id": id,
    "shape": "rect",
    "coords": coords?.map((coord) => coord / ratio) || [],
    "fillColor": '#0000',
    "lineWidth": 2,
    "strokeColor": "#ffffff",
    "href": `/people/${person_id}`
  })), [photo, ratio])

  const onTagDelete = (tagId) => {
    const tag = photo.tags.find(({ id }) => id === tagId)

    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць тэг: ${tag?.title}?`)) {
      deleteTag(tagId).then((tag) => {
        showSuccess('Тэг пасьпяхова выдалены')
        setPhoto({ ...photo, tags: [  ...photo?.tags.filter(({ id }) => id !== tag?.id) ]})
      }).catch((respose) => {
        showError()
      })
    }
  }

  const onPhotoDelete = () => {
    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць фотаздымак?`)) {
      deletePhoto(photo.id).then((response) => {
        showSuccess('Фотаздымак пасьпяхова выдалены')

        setPhotos((photos) => photos.filter(({ id: photoId }) => photoId != id))
        setId(null)
      }).catch((respose) => {
        showError()
      })
    }
  }

  const onTagCreate = ({ value }) => {
    const params = {
      tag: {
        photo_id: photo?.id,
        person_id: value,
        data: { coords }
      }
    }

    createTag(params).then((tag) => {
      showSuccess('Тэг пасьпяхова дададзены')
      setPhoto({ ...photo, tags: [...photo?.tags, tag.attributes] })
      setCoords(null)
      jQuery('img.img-mapper-img').data('imgAreaSelect').cancelSelection()
    }).catch((respose) => {
      showError()
    })
  }

  const onSumbit = () => {
    const params = { date: photo?.date_at, description: photo?.description, status: photo?.status }

    updatePhoto(photo?.id, params).then((photo) => {
      showSuccess('Фотаздымак пасьпяхова абноўлены')

      setId(null)
    }).catch((respose) => {
      showError()
    })
  }

  const toggleSelectedTagIndex = (event, index) => {
    event.preventDefault()

    if (coords && coords[0] !== coords[2] && selectedTagIndex === index) {
      const id = photo.tags[index].id
      const params = { tag: { data: { coords }}}

      updateTag(id, params).then((tag) => {
        showSuccess('Тэг пасьпяхова абноўлены')
        setPhoto({ ...photo, tags: [...photo.tags.slice(0, index), tag, ...photo.tags.slice(index + 1, photo.tags.length)] })
        setCoords(null)
        setSelectedTagIndex(null)
        jQuery('img.img-mapper-img').data('imgAreaSelect').cancelSelection()
        document.getElementsByTagName('area')[index]?.dispatchEvent(MOUSEOVER)
      }).catch((respose) => {
        showError()
      })
    } else {
      setSelectedTagIndex(index)
    }
  }

  return <Modal
    isOpen={id ? true : false}
    contentLabel='Рэдагаваньне фотаздымка'
    onRequestClose={onRequestClose}
  >
    {
      photo ? (
        <Container>
          {/* TODO: Add buttons */}
          <div className="Tools">
            {/* <a data-confirm="Вы ўпэўнены, што жадаеце павярнуць фотаздымак?" className="btn disabled" rel="nofollow" data-method="post" href="/photos/253/rotate?angle=-90">
              <i className="fa fa-undo"></i>
            </a>
            <a data-confirm="Вы ўпэўнены, што жадаеце павярнуць фотаздымак?" className="btn disabled" rel="nofollow" data-method="post"
            href="/photos/253/rotate?angle=90">
              <i className="fa fa-repeat"></i>
            </a>
            <a data-confirm="Вы ўпэўнены, што жадаеце абрэзаць фотаздымак?" id="crop" className="btn disabled" rel="nofollow" data-method="post" href="/photos/253/crop">
              <i className="fa fa-crop"></i>
            </a> */}
            {
              photo?.destroyable && (
                <button className="btn Tools-Item" rel="nofollow" onClick={onPhotoDelete}>
                  <i className="fa fa-trash"></i>
                </button>
              )
            }
            {
              photo?.file?.url && (
                <a href={photo?.file?.url} className="btn Tools-Item" rel="nofollow" target='_blank'>
                  <i className="fa fa-download"></i>
                </a>
              )
            }
          </div>
          <hr/>
          <div className="image" style={{ aspectRatio: photo?.ratio }}>
            {
              photo && (
                <ImageMapper
                  src={photo?.file?.medium_url || ''}
                  onLoad={onLoadImageMapper}
                  onClick={onClickImageMapper}
                  map={{ name: 'map', areas: areas || [] }}
                  // width={'100'}
                  // height={'100'}
                  // imgWidth={'100'}
                  // imgHeight={'100'}
                  // natural={true}
                />
              )
            }
          </div>
          <map name="photo">
            {
              photo?.tags?.map(({ id, coords, person }, index) => (
                <area key={index} id={id} href={person ? `/people/${id}` : null} shape='rect' coords={coords?.join(',')} alt={person?.full_name} title={person?.full_name}/>
              ))
            }
          </map>
          <hr/>
          <div className='TagsWrapper'>
            {
              photo?.tags.map(({ id, title, person_id }, index) => {
                return (
                  <span
                    className='Tags'
                    key={id}
                    onMouseEnter={onMouseAreaTrigger(index, MOUSEOVER)}
                    onMouseLeave={onMouseAreaTrigger(index, MOUSEOUT)}
                  >
                    [{
                      <span className='Actions'>
                        <Link to={`/people/${person_id}`}>{title}</Link>
                        <Link className={cx({ 'Tag__Selected': selectedTagIndex === index })} onClick={(e) => toggleSelectedTagIndex(e, index)}><i className="fa fa-pencil"></i></Link>
                        <button href={`/tags/${id}`} onClick={() => onTagDelete(id)}><i className="fa fa-trash"></i></button>
                      </span>
                    }]
                  </span>
                )
              })
            }
          </div>
          <br/>
          <Select label={'Дадаць тэг'} emptyLabel value='' onChange={onTagCreate} options={peopleOptions}/>
          <TextField label={'Дата'} type='date' value={formatInputDate(photo?.date_at) || ''} onChange={onChangeByName('date_at')}/>
          <Textarea label={'Апісаньне'} value={photo?.description || ''} onChange={onChangeByName('description')}/>
          <Select label={'Статус'} value={statusOptions.find(({ value }) => value == photo?.status)} options={statusOptions} onChange={onChangeByName('status')} menuPlacement='auto'/>
          <hr/>
          <button className="Button btn btn btn-default" onClick={onSumbit}>Захаваць</button>
        </Container>
      ) : (
        <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
      )
    }
  </Modal>
}
