const PhotoLoader = () => {
  return (
    <div className='photo-spinner justify-content-center'>
      <div className='spinner-border' role='status'>
        <span className='sr-only'>
          Loading...
        </span>
      </div>
    </div>
  )
}

export default PhotoLoader
