import { useState, useContext } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import 'helpers/plugins/imgAreaSelect/imgAreaSelect.css'

import { MessageContext } from 'App'
import { PermissionForm } from 'components'
import { createPermission } from 'api'

const Container = styled.div`
`

const PermissionsNewPage = () => {
  const { showSuccess, showError } = useContext(MessageContext)
  const navigate = useNavigate()
  const [permission, setPermission] = useState({})
  const [searchParams, _] = useSearchParams()

  const onCreate = () => {
    const params = {
      permission: {
        recipient_id: permission.recipient_id,
        show_attributes: {
          person_ids: permission.show
        },
        edit_attributes: {
          person_ids: permission.edit
        }
      }
    }

    createPermission(params).then((permission) => {
      showSuccess('Правы доступа пасьпяхова дададзены')

      setPermission(permission)

      navigate(searchParams.get('redirect_path') || '/settings/permissions')
    }).catch((respose) => {
      showError()
    })
  }

  return (
    <Container>
      <br/>
      <Link className='text-dark' onClick={() => window.history.back()}>Назад</Link>
      <br/>
      <br/>
      <h2>Дадаваньне правоў доступа</h2>
      <br/>
      <PermissionForm permission={permission} setPermission={setPermission} onSubmit={onCreate}/>
    </Container>
  )
}

export default PermissionsNewPage
