import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { formatDatetime } from 'helpers/utils'
import cx from 'classnames'

const EventComponent = ({ read, description, created_at, actions }) => {
  return (
    <tr className={cx({ unread: !read })}>
      <td>
        {
          description
        }
      </td>
      <td>{formatDatetime(created_at)}</td>
      <td className="actions">
        {
          actions.map(({ icon, onClick }, index) => {
            return (
              <button key={index} className="btn btn-default" onClick={onClick}>
                <i className={cx('fa', icon)}></i>
              </button>
            )
          })
        }
      </td>
    </tr>
  )
}

const Event = ({ event, onEventDelete, onEventAccept, onEventDecline, currentPersonId }) => {
  const {
    attributes: {
      id,
      type,
      read,
      created_at,
      created_by: {
        id: createdById,
        full_name: createdByFullName
      },
      subject: {
        id: subjectId,
        action: subjectAction,
        status: subjectStatus,
        creator_id: subjectCreatorId,
        creator_full_name: subjectCreatorFullName,
        subject_id: subjectSubjectId,
        subject_full_name: subjectSubjectFullName
      },
      recipient: {
        id: recipientId,
        full_name: recipientFullName
      }
    }
  } = event

  const actionLabel = useMemo(() => subjectAction === 'show' ? 'прагляд' : 'рэдагаваньне', [subjectAction])
  const createdBy = useMemo(() => <Link to={`/people/${createdById}`}>{createdByFullName}</Link>, [createdById, createdByFullName])
  const recipient = useMemo(() => <Link to={`/people/${recipientId}`}>{recipientFullName}</Link>, [recipientId, recipientFullName])
  const subjectCreator = useMemo(() => <Link to={`/people/${subjectCreatorId}`}>{subjectCreatorFullName}</Link>, [subjectCreatorId, subjectCreatorFullName])
  const subjectSubject = useMemo(() => <Link to={`/people/${subjectSubjectId}`}>{subjectSubjectFullName}</Link>, [subjectCreatorId, subjectCreatorFullName])
  const wrap = useCallback((element) => <>{createdBy}{element}{recipient}</>, [createdBy, recipient])

  const deleteAction = useMemo(() => ({ icon: 'fa-trash', onClick: () => onEventDelete(id) }), [id, onEventDelete])
  const acceptAction = useMemo(() => ({ icon: 'fa-check', onClick: () => onEventAccept(id) }), [id, onEventAccept])
  const declineAction = useMemo(() => ({ icon: 'fa-times', onClick: () => onEventDecline(id) }), [id, onEventDecline])

  const CreatedPersonEvent = () => {
    const description = wrap(' дадаў чалавека ')

    return <EventComponent read={read} description={description} created_at={created_at} actions={[deleteAction]}/>
  }

  const UpdatedPersonEvent = () => {
    const description = wrap(' абнавіў чалавека ')

    return <EventComponent read={read} description={description} created_at={created_at} actions={[deleteAction]}/>
  }

  const DestroyedPersonEvent = () => {
    const description = wrap(' выдаліў чалавека ')

    return <EventComponent read={read} description={description} created_at={created_at} actions={[deleteAction]}/>
  }

  const CreatedPermissionEvent = () => {
    const description = wrap(' дадаў правы для ')


    return <EventComponent read={read} description={description} created_at={created_at} actions={[deleteAction]}/>
  }

  const DestroyedPermissionEvent = () => {
    const description = wrap(' выдаліў правы для ')

    return <EventComponent read={read} description={description} created_at={created_at} actions={[deleteAction]}/>
  }

  const RequestedPermissionEvent = () => {
    const pendingDescription = () => <>{subjectCreator}{` запрасіў правы на ${actionLabel} старонкі `}{subjectSubject}</>
    const acceptedDescription = () => <>{'Вы прынялі запыт '}{subjectCreator}{` на ${actionLabel} старонкі `}{subjectSubject}</>
    const declinedDescription = () => <>{'Вы адхілілі запыт '}{subjectCreator}{` на ${actionLabel} старонкі `}{subjectSubject}</>

    const descriptionByStatus = () => {
      switch(subjectStatus) {
        case 'pending': return pendingDescription()
        case 'accepted': return acceptedDescription()
        case 'declined': return declinedDescription()
        default: return
      }
    }

    const actions = subjectStatus === 'pending' ? [acceptAction, declineAction] : [deleteAction]

    return <EventComponent read={read} description={descriptionByStatus()} created_at={created_at} actions={actions}/>
  }

  const PermissionAcceptedEvent = () => {
    const description = <>{createdBy}{` прыняў ваш запыт на ${actionLabel} старонкі `}{subjectSubject}</>

    return <EventComponent read={read} description={description} created_at={created_at} actions={[deleteAction]}/>
  }

  const PermissionDeclinedEvent = () => {
    const description = <>{createdBy}{` адхіліў ваш запыт на ${actionLabel} старонкі `}{subjectSubject}</>

    return <EventComponent read={read} description={description} created_at={created_at} actions={[deleteAction]}/>
  }

  const UpdatedPermissionEvent = () => {
    const link = <Link to={`/settings/permissions/${subjectId}/edit`}>правы доступа</Link>
    let description;

    if (currentPersonId === createdById) {
      description = <>{`Вы абнавілі `}{link}{` для `}{recipient}</>
    } else if (currentPersonId === recipientId) {
      description = <>{createdBy}{` абнавіў для вас `}{link}</>
    } else {
      description = <>{createdBy}{` абнавіў `}{link}{` для `}{recipient}</>
    }

    return <EventComponent read={read} description={description} created_at={created_at} actions={[deleteAction]}/>
  }

  const Component = () => {
    return {
      'Event::CreatedPerson': <CreatedPersonEvent/>,
      'Event::UpdatedPerson': <UpdatedPersonEvent/>,
      'Event::DestroyedPerson': <DestroyedPersonEvent/>,
      'Event::CreatedPermission': <CreatedPermissionEvent/>,
      'Event::DestroyedPermission': <DestroyedPermissionEvent/>,
      'Event::RequestedPermission': <RequestedPermissionEvent/>,
      'Event::PermissionAccepted': <PermissionAcceptedEvent />,
      'Event::PermissionDeclined': <PermissionDeclinedEvent />,
      'Event::UpdatedPermission': <UpdatedPermissionEvent />
    }[type]
  }

  return <Component />
}

export default Event
