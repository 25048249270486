import { useEffect, useState, useRef, useMemo, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { setCurrentUser, setPreviousPath } from 'store/actions';
import { MessageContext } from 'App'
import { getPersonShort, getPeopleList, updatePerson, createRequestPermission } from 'api'
import { Select, PersonCard } from 'components'
import { peopleToSelectOptions } from 'helpers/utils'
import { loadingStatuses } from '../constants'
import { WithLoader } from 'components'

const Container = styled('div')`
  padding-top: 16px;
  flex-direction: column;
  display: flex;
  gap: 32px;

  .Parents {
    justify-content: space-evenly;
    display: flex;
  }

  .Person {
    justify-content: space-evenly;
    display: flex;
  }

  .Children {
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
`

const PersonalPage = ({ currentUser, setLoading, previousPath, setPreviousPath }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { showSuccess, showError } = useContext(MessageContext)
  const { id } = useParams()
  const [person, setPerson] = useState(null)
  const [peopleList, setPeopleList] = useState(null)

  useEffect(() => {
    setPerson(null)
    setLoading(loadingStatuses.PENDING)
  }, [id])

  const addFather = (id) => (
    updatePerson(person?.id, { father_id: id }).then((person) => {
      showSuccess('Бацька пасьпяхова дабаўлены')
      setPerson(person)
    })
  )

  const addMother = (id) => (
    updatePerson(person?.id, { mother_id: id }).then((person) => {
      showSuccess('Маці пасьпяхова дабаўлена')
      setPerson(person)
    })
  )

  const addChild = (id) => (
    updatePerson(id, { [person?.gender === 'man' ? 'father_id' : 'mother_id']: person?.id }).then((child) => {
      showSuccess(`Дзіцё пасьпяхова дабаўлена`)

      setPerson({
        ...person,
        children: [
          ...person.children, child
        ]
      })
    })
  )

  const filterFather = ({ attributes: { gender } }) => (gender === 'man')
  const filterMother = ({ attributes: { gender } }) => (gender !== 'man')

  const fatherModalSettings = {
    title: 'Бацька',
    addPerson: addFather,
    options: useMemo(() => peopleList?.filter(filterFather).map(peopleToSelectOptions), [peopleList]),
    newPersonLink: `/people/new?child_id=${person?.id}&redirect_path=%2Fhome%2F${person?.id}`
  }

  const motherModalSettings = {
    title: 'Маці',
    addPerson: addMother,
    options: useMemo(() => peopleList?.filter(filterMother).map(peopleToSelectOptions), [peopleList]),
    newPersonLink: `/people/new?child_id=${person?.id}&redirect_path=%2Fhome%2F${person?.id}`
  }

  const childModalSettings = {
    title: 'Дзіцё',
    addPerson: addChild,
    options: useMemo(() => peopleList?.map(peopleToSelectOptions), [peopleList]),
    newPersonLink: `/people/new?${person?.gender === 'man' ? 'father' : 'mother'}_id=${person?.id}&redirect_path=%2Fhome%2F${person?.id}`
  }

  const [modalSettings, setModalSettings] = useState(motherModalSettings)

  const setMotherModalSettings = () => {
    setModalSettings(motherModalSettings)
  }

  const setFatherModalSettings = () => {
    setModalSettings(fatherModalSettings)
  }

  const setChildModalSettings = () => {
    setModalSettings(childModalSettings)
  }

  const closeModalButtonRef = useRef(null)

  const sendRequest = useCallback((personId) => {
    const params = { person_id: personId, action: 'show' }

    createRequestPermission({ request_permission: params }).then((requestPermission) => {
      showSuccess('Запыт пасьпяхова адпраўлены')
    }).catch(() => {
      showError()
    })
  }, [])

  useEffect(() => {
    getPersonShort(id || currentUser.person_id).then((person) => {
      setPerson(person)
      setPreviousPath(location.pathname)
    }).catch((response) => {
      const data = response.response.data.data

      const subject = <Link to={`/people/${data.subject.id}`}>{data.subject.full_name}</Link>
      const author = <Link to={`/people/${data.subject.created_by_id}`}>аўтара</Link>
      const request = <button onClick={() => sendRequest(data.subject.id)}>сюды</button>
      const message = <>Вы ня маеце правоў на прагляд старонкі {subject}. Каб запытаць дазвол ў {author} націсьніце {request}</>

      showError(message)
      navigate(previousPath || '/')
    }).finally(() => {
      setLoading(loadingStatuses.LOADED)
    })
  }, [id, currentUser.person_id, showError, sendRequest])

  useEffect(() => {
    getPeopleList().then(setPeopleList)
  }, [])

  const addPersonChange = (e) => {
    if (e === '') {
      return
    }

    modalSettings.addPerson(e.value).then(() => {
      closeModal()
    })
  }

  const closeModal = () => {
    closeModalButtonRef.current.click()
  }

  return (
    <Container>
      {
        person && (
          <>
            <div className='container col-md-6 col-sm-12'>
              <div className='row'>
                <PersonCard person={person?.father} onOpenModal={setFatherModalSettings}/>
                <PersonCard person={person?.mother} onOpenModal={setMotherModalSettings}/>
              </div>
              <div className='row'>
                <PersonCard person={person} path={`/people/${person?.id}`}/>
              </div>
              <div className='row'>
                {
                  person?.children && [...person?.children].map((child, index) => {
                    return (
                      <PersonCard key={index} person={child}/>
                    )
                  })
                }
                <PersonCard onOpenModal={setChildModalSettings}/>
              </div>
            </div>
            <div aria-labelledby="myModalLabel" className="modal fade" id="modal-child" role="dialog" aria-modal="true" aria-hidden='true'>
              <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content">
                  <div className="modal-header d-block">
                    <button ref={closeModalButtonRef} aria-label="Close" className="close float-right" data-dismiss="modal"
                      type="button"><span aria-hidden="true">×</span></button>
                    <button aria-label="Close" className="close float-left"
                      data-dismiss="modal" type="button"><span aria-hidden="true">&nbsp;</span></button>
                    <h5 className="modal-title text-center">{modalSettings.title}</h5>
                  </div>
                  <div className="modal-body text-center">
                    <div className="actions">
                      <div className="form-group select optional person_child_id">
                        <Select onChange={addPersonChange} placeholder="Выбраць..." options={modalSettings.options}/>
                      </div>
                      <hr/>
                      <Link className="btn btn-default" onClick={closeModal} to={modalSettings.newPersonLink}>
                        Дадаць новага чалавека
                      </Link>
                    </div>
                    <div className="spinner justify-content-center" style={{display: "none"}}>
                      <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </Container>
  )
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
    previousPath: state.main.previousPath,
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
    setPreviousPath: (path) => dispatch(setPreviousPath(path)),
  })
)(WithLoader(PersonalPage));
