import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as reducers from './reducers';

const initialState = {
  main: {
    currentUser: null,
    csrfToken: null,
    unreadEventsCount: 0,
  }
};

const store = createStore(
  combineReducers({ ...reducers }),
  initialState,
  applyMiddleware(thunk)
);

export default store;
