import { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ReactSelect from 'react-select'

import { TextField, Textarea, Select, Select2, ImageField } from 'components'
import { getPeopleList } from 'api'
import { peopleToSelectOptions, formatInputDate } from 'helpers/utils'

const Container = styled.div`
  .preview, .photo {
    img {
      max-height: 200px;
    }
  }
`

const PermissionForm = ({ permission, setPermission, onSubmit }) => {
  const [peopleList, setPeopleList] = useState([])
  const [selectedShowPeople, setSelectedShowPeople] = useState([])
  const [selectedEditPeople, setSelectedEditPeople] = useState([])

  const peopleOptions = useMemo(() => peopleList?.map(peopleToSelectOptions), [peopleList])

  useEffect(() => { getPeopleList().then(setPeopleList) }, [])

  useEffect(() => {
    if (permission?.show) {
      const permissionShowPersonIds = permission.show.map(({ id }) => id)

      setSelectedShowPeople(peopleOptions.filter(({ value }) => permissionShowPersonIds.includes(parseInt(value))))
    }
  }, [permission?.show, peopleOptions])

  useEffect(() => {
    if (permission?.edit) {
      const permissionEditPersonIds = permission.edit.map(({ id }) => id)

      setSelectedEditPeople(peopleOptions.filter(({ value }) => permissionEditPersonIds.includes(parseInt(value))))
    }
  }, [permission?.edit, peopleOptions])

  const recipientValue = useMemo(() => {
    if (permission?.recipient_id && peopleOptions.length) {
      return peopleOptions.find(({ value }) => value == permission?.recipient_id)
    }
  }, [permission?.recipient_id, peopleOptions])


  const onChangeSelectByName = (name) => ({ value }) => {
    setPermission({ ...permission, [name]: value })
  }

  const onChangePeopleShowSelected = (options, _) => {
    setPermission({
      ...permission,
      show: options.map(({ value }) => ({ id: parseInt(value) }))
    })
  }

  const onChangePeopleEditSelected = (options, _) => {
    setPermission({
      ...permission,
      edit: options.map(({ value }) => ({ id: parseInt(value) }))
    })
  }

  return (
    <Container>
      <Select label={'У дачыненьні да'} id={'recipient_id'} emptyLabel value={recipientValue} onChange={onChangeSelectByName('recipient_id')} options={peopleOptions}/>

      <div>
        <div className='card form-group'>
          <div className='card-header'>Можа праглядаць</div>
          <li className='list-group-item form-check'>
            <ReactSelect
              isMulti
              placeholder="Людзі"
              value={selectedShowPeople}
              onChange={onChangePeopleShowSelected}
              options={peopleOptions}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </li>
        </div>
      </div>
      <div>
        <div className='card form-group'>
          <div className='card-header'>Можа рэдагаваць</div>
          <li className='list-group-item form-check'>
            <ReactSelect
              isMulti
              placeholder="Людзі"
              value={selectedEditPeople}
              onChange={onChangePeopleEditSelected}
              options={peopleOptions}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </li>
        </div>
      </div>
      <button className="btn btn-default" onClick={onSubmit}>Захаваць</button>
    </Container>
  )
}

export default PermissionForm
