import { connect } from 'react-redux'
import { useEffect, useState, useMemo, useRef, useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import 'helpers/plugins/imgAreaSelect/imgAreaSelect.css'

import { MessageContext } from 'App'
import { getPermissions, deletePermission } from 'api'

const Container = styled.div`
`

const PermissionsPage = ({ currentUser }) => {
  const [permissions, setPermissions] = useState([])
  const { showSuccess, showError } = useContext(MessageContext)

  useEffect(() => { getPermissions().then(setPermissions) }, [])

  const onDelete = (id) => {
    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць правы доступа?`)) {
      deletePermission(id).then((response) => {
        showSuccess('Права доступа пасьпяхова выдалена')

        setPermissions([...permissions.filter(({ attributes: { id }}) => id != response.id)])
      }).catch((respose) => {
        showError()
      })
    }
  }

  return (
    <Container>
      <br/>
      <div className='card'>
        <div className='card-header text-center'>Кіраваньне правамі доступа</div>
        <div className='card-body'>
          <ul className='list-group Permissions'>
            {
              permissions.map(({ attributes: { id, recipient, creator }}) => {
                const link = <Link to={`/settings/permissions/${id}`}>правы доступа</Link>

                return (
                  <li key={id} className='list-group-item text-center'>
                    {
                      creator?.id === currentUser?.id && (
                        <>{`Вы далі `}{link}{` для `}<Link to={`/people/${recipient?.id}`}>{recipient?.full_name}</Link></>
                      )
                    }
                    {
                      recipient?.id === currentUser?.person_id && (
                        <><Link to={`/people/${creator?.person_id}`}>{creator?.full_name}</Link>{` да${creator?.gender === 'woman' ? 'лa' : 'ў'} для вас `}{link}</>
                      )
                    }
                    {
                      creator?.id === currentUser?.id && (
                        <button className="btn float-right text-dark" onClick={() => onDelete(id)}>
                          <i className="fa fa-times"></i>
                        </button>
                      )
                    }
                    {
                      creator?.id === currentUser?.id && (
                        <Link className="btn float-right text-dark" to={`/settings/permissions/${id}/edit`}>
                          <i className="fa fa-pencil"></i>
                        </Link>
                      )
                    }
                  </li>
                )
              })
            }
            <Link className='text-center list-group-item' to='/settings/permissions/new'>Дадаць правы</Link>
          </ul>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser
  }),
  (dispatch) => ({
  })
)(PermissionsPage);
